import React from 'react'

export default function MainContainer({ children }) {
    return (
        <main className='flex flex-col min-h-screen w-screen'>
            <div className='flex-grow'>
                {children}
            </div>
        </main>
    )
}
