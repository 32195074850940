import React, { useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';


const Carousel = ({ services }) => {

    return (
        <InfiniteScroll
            dataLength={services?.serviceList.length}
            className='flex flex-row gap-4'
        >
            {services?.serviceList?.map((service, index) => (
                <div className=" flex-shrink-0 w-[30%] lg:h-64 md:w-[25%] lg:w-[18%]  rounded-xl  shadow-md hover:shadow-lg flex flex-col items-center pb-4 my-6  transition-transform trasform hover:scale-105"
                >
                    <img
                        src={service.icon}
                        className="w-full rounded-xl mb-5"
                    />
                    <div className="text-center flex flex-grow   items-end">
                        <p className="text-xs">{service.serviceName}</p>
                    </div>
                </div>
            ))}

        </InfiniteScroll>


    );
};


export default Carousel;