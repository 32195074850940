import React from 'react'
import { Link } from 'react-router-dom';

export default function Privacy() {
    return (
        <div className="px-8 lg:px-28 py-8 text-black">

                <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>

                <p className="mb-4">
                    At GitSetCode Software Solutions, we take your privacy seriously. This Privacy Policy explains how we collect, use,
                    disclose, and safeguard your information when you use our website <Link to={'/'}>www.gitsetcode.com</Link>.
                </p>

                <h2 className="text-2xl font-bold mb-2">Information We Collect</h2>

                <p className="mb-4">
                    We may collect personal information that you provide directly to us when you [create an account / fill out a
                    form / contact us]. This information may include [
                    name, email address, phone number, etc.].
                </p>

                <h2 className="text-2xl font-bold mb-2">How We Use Your Information</h2>

                <p className="mb-4">
                    The information we collect from you may be used to [provide and maintain our services / improve user
                    experience / send promotional emails / respond to inquiries].
                </p>

                <h2 className="text-2xl font-bold mb-2">Third-Party Disclosure</h2>

                <p className="mb-4">
                    We do not share your details with any third party.
                </p>

                <h2 className="text-2xl font-bold mb-2">Security</h2>

                <p className="mb-4">
                    We take reasonable measures to protect your information from unauthorized access or disclosure.
                </p>

                {/* <h2 className="text-2xl font-bold mb-2">Cookies</h2>

                <p className="mb-4">
                    We use cookies and similar tracking technologies to [explain how you use cookies, e.g., for website analytics,
                    personalization, etc.].
                </p> */}

                {/* <h2 className="text-2xl font-bold mb-2">Your Choices</h2>

                <p className="mb-4">
                    You can [list the options users have to manage their information, e.g., update profile, opt-out of marketing
                    emails, etc.].
                </p> */}

                <h2 className="text-2xl font-bold mb-2">Updates to This Privacy Policy</h2>

                <p className="mb-4">
                    We may update this Privacy Policy from time to time, and the updated version will be posted on this webpage.
                    Please review this page periodically for any changes.
                </p>

                <h2 className="text-2xl font-bold mb-2">Contact Us</h2>

                <p className="mb-4">
                    If you have any questions about this Privacy Policy, you can contact us at <a href="mailto:gitsetcode.softwaresolutions@gmail.com">gitsetcode.softwaresolutions@gmail.com</a>.
                </p>

            </div>

    )
}
