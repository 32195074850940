import "./App.css";
import { Routes, Route, ScrollRestoration } from 'react-router-dom';
import { About, Error, Home, Privacy, ComingSoon, Contact } from "./pages";
import { PAGES } from "./constants";
import { Footer, FooterLong, MenuBar, TopNav, FAQs } from "./components";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";


function App() {
  const location = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const HeaderComp = () => {
    return (
      <>
        <header className={`${!location === PAGES.PRIVACY ? 'sticky top-0' : ''}`}>
          <TopNav fromPrivacy={location === PAGES.PRIVACY} />
        </header>
        {
          !(location === PAGES.PRIVACY) &&
          <header style={{ zIndex: 100 }} className='sticky top-0'>
            <MenuBar />
          </header>
        }
      </>

    )
  }

  const FooterComp = () => {
    return (
      <footer>
        <FAQs fromContact={location === PAGES.CONTACT} />
        <FooterLong />
        <Footer fromPrivacy={location === PAGES.PRIVACY} />
      </footer>
    )
  }

  return (
    <>
      {HeaderComp()}
      <Routes>
        <Route path={`${PAGES.INDEX}`} element={<Home />} />
        <Route path={`${PAGES.ABOUT}`} element={<About />} />
        <Route path={`${PAGES.SERVICES}`} element={<ComingSoon />} />
        <Route path={`${PAGES.CONTACT}`} element={<Contact />} />
        <Route path={`${PAGES.PRIVACY}`} element={<Privacy />} />
        <Route path={`*`} element={<Error />} />
      </Routes>

      {FooterComp()}
    </>
  )
}

export default App;
