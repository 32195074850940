export default [
    'Web Design',
    'Web Development',
    'Mobile App Development',
    'Ecommerce Solutions',
    'Digital Marketing',
    // 'Reputation Management',
    'Graphic Designing',
    // 'Social Media Marketing',
    // 'Brand Promotion',
    // 'Adwords/PPC',
    'SAAS Development',
    'SEO',
    'Social Media Optimization'
]