import ReactIcon from "../../assets/TechStack/react-js-icon.svg";
import ReduxIcon from "../../assets/TechStack/redux-icon.svg";
import NextjsIcon from "../../assets/TechStack/nextjs-icon.svg";
import ViewIcon from "../../assets/TechStack/vue-js-icon.svg";
import AngularIcon from "../../assets/TechStack/angular-icon.svg";
import NodejsIcon from "../../assets/TechStack/node-js-icon.svg";
import MongoDBIcon from "../../assets/TechStack/mongodb-icon.svg";
import MySQLIcon from "../../assets/TechStack/mysql-icon.svg";
import GraphQlIcon from "../../assets/TechStack/graphql-icon.svg";
import PhpIcon from "../../assets/TechStack/php.svg";
import LaravelIcon from "../../assets/TechStack/laravel-icon.svg";
import FlutterIcon from "../../assets/TechStack/Flutter.png";
import SwiftIcon from "../../assets/TechStack/swift.svg";
import PhotoShopIcon from "../../assets/TechStack/adobe-photoshop-icon.svg";
import FigmaIcon from "../../assets/TechStack/figma.svg";
import IllustratorIcon from "../../assets/TechStack/adobe-illustrator-icon.svg";
import InDesignIcon from "../../assets/TechStack/indesign-icon.svg";
import CorelDrawIcon from "../../assets/TechStack/coreldraw.svg";
import XDIcon from "../../assets/TechStack/adobe-xd-icon.svg";
import AWSIcon from "../../assets/TechStack/aws-icon.svg";
import GoogleCloudIcon from "../../assets/TechStack/google-cloud-icon.svg";
import AZUREIcon from "../../assets/TechStack/azure-icon.svg";

const techStack = [
  {
    id: 1,
    title: "Design & Development",
    technologyList: [
      {
        id: 1,
        techName: "REACT JS",
        icon: ReactIcon,
      },
      {
        id: 2,
        techName: "R Native",
        icon: ReactIcon,
      },
      {
        id: 3,
        techName: "Redux",
        icon: ReduxIcon,
      },
      {
        id: 4,
        techName: "Next Js",
        icon: NextjsIcon,
      },
      {
        id: 5,
        techName: "Vue Js",
        icon: ViewIcon,
      },
      {
        id: 6,
        techName: "Angular",
        icon: AngularIcon,
      },
      {
        id: 7,
        techName: "Node Js",
        icon: NodejsIcon,
      },
      {
        id: 8,
        techName: "MongoDB",
        icon: MongoDBIcon,
      },
      {
        id: 9,
        techName: "My Sql",
        icon: MySQLIcon,
      },
      {
        id: 10,
        techName: "GraphQl",
        icon: GraphQlIcon,
      },
      {
        id: 11,
        techName: "Php",
        icon: PhpIcon,
      },
      {
        id: 12,
        techName: "Laravel",
        icon: LaravelIcon,
      },
      {
        id: 13,
        techName: "Flutter",
        icon: FlutterIcon,
      },
      {
        id: 14,
        techName: "Swift",
        icon: SwiftIcon,
      },
    ],
  },
  {
    id: 2,
    title: "Graphics & Prototyping",
    technologyList: [
      {
        id: 1,
        techName: "Figma",
        icon: FigmaIcon,
      },
      {
        id: 2,
        techName: "Photoshop",
        icon: PhotoShopIcon,
      },
      {
        id: 3,
        techName: "Illustrator",
        icon: IllustratorIcon,
      },
      {
        id: 4,
        techName: "InDesign",
        icon: InDesignIcon,
      },
      {
        id: 6,
        techName: "XD",
        icon: XDIcon,
      },
    ],
  },
  {
    id: 3,
    title: "Cloud & Servers",
    technologyList: [
      {
        id: 1,
        techName: "AWS",
        icon: AWSIcon,
      },
      {
        id: 2,
        techName: "GOOGLE CLOUD",
        icon: GoogleCloudIcon,
      },
      {
        id: 3,
        techName: "AZURE",
        icon: AZUREIcon,
      },
    ],
  },
];

export default function TechStack() {
  return (
    <>
      <div className="w-full  flex flex-col gap-3 my-10  px-8 lg:px-28">
        <div className="w-full flex items-center justify-center">
          <p className="text-2xl md:text-4xl lg:text-5xl font-semibold text-primary-color">
            Our Tech Stack
          </p>
        </div>
        <div className="w-full  text-center">
          <p className="text-base">
            Maximizing Business Value through the Appropriate Tech Stack
          </p>
        </div>
        <div className="w-full flex flex-col gap-10">
          {techStack &&
            techStack.map((item) => {
              return (
                <div key={item.id} className="w-full flex flex-col">
                  <p className="text-lg md:text-xl lg:text-2xl font-semibold mb-3 lg:mb-5 text-start">
                    {item.title}
                  </p>
                  <div className="w-full flex flex-row items-center gap-6 overflow-x-scroll overflow-y-clip no-scrollbar justify-start">
                    {item.technologyList &&
                      item.technologyList.map((techItem) => {
                        return (
                          <div
                            key={techItem.id}
                            className="py-4 bg-transparent rounded-md flex flex-col items-center justify-between   transition-transform transform hover:scale-105 max-h-72 gap-2 md:gap-3 lg:gap-5"
                          >
                            <div className="bg-white h-20 w-20 md:h-36 md:w-36 lg:h-40 lg:w-40 flex flex-row justify-center border-[1px] items-center shadow-lg rounded-full px-2 py-2">
                              <img
                                src={techItem.icon}
                                alt={techItem.techName}
                                // height={30}
                                // width={30}
                                className="h-10 md:h-18 lg:h-20"
                              />
                            </div>
                            <p className="capitalize font-semibold text-sm md:text-lg lg:text-lg">
                              {techItem.techName.toUpperCase()}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
