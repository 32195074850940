import React from "react";
import { MainContainer, ServiceStack, TechStack } from "../components";
import { PAGES } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link as ScrollLink } from 'react-scroll';
const benefit = [
  {
    id: 1,
    serialNo: "01",
    title: "Tailored Solutions",
    content:
      "We recognize the uniqueness of every business and thus offer personalized IT solutions that align with your business aspirations. Whether you require software development, web design, digital marketing, or IT consulting services, we collaborate closely with you to craft custom solutions that tackle your specific challenges and foster growth.",
  },
  {
    id: 2,
    serialNo: "02",
    title: "Smart Investment",
    content:
      "GITSETCODE Software Solutions comprehends the significance of smart investments in today's competitive business landscape. Our pricing models are transparent and competitive, ensuring that you access top-quality services at a reasonable expense. We also concentrate on streamlining processes and optimizing resource utilization to deliver cost-efficient solutions without compromising quality.",
  },
  {
    id: 3,
    serialNo: "03",
    title: "Adaptability and Flexibility",
    content:
      "Our offerings and services are designed to expand and adapt to evolving business requisites. GITSETCODE Software Solutions presents flexible and customizable options to accommodate diverse business demands. As a prominent Tech Company, we boast a proven track record of effectively implementing solutions for small startups to large enterprises.",
  },
  {
    id: 4,
    serialNo: "04",
    title: "Domain Proficiency",
    content:
      "Backed by our extensive experience in the IT sector, we have cultivated robust expertise across various domains. Our team of adept professionals is well-versed in the latest technologies and trends, ensuring the delivery of excellent solutions tailored to fulfill your precise requirements.",
  },
  {
    id: 5,
    serialNo: "05",
    title: "Excellence and Dependability",
    content:
      "Excellence remains at the core of our operations. We adhere rigorously to industry best practices and adhere to stringent quality assurance protocols to supply resilient, dependable, and scalable solutions. Our unwavering commitment to excellence guarantees that you receive products and services that meet the highest benchmarks, empowering you to outshine the competition.",
  },
  {
    id: 6,
    serialNo: "06",
    title: "Robust Client Partnerships",
    content:
      "Nurturing customer satisfaction stands as our paramount objective. We place paramount importance on fostering enduring relationships with our clients through exceptional customer experiences and support. Our dedicated team is perpetually accessible to address your inquiries, attend to your concerns, and offer timely aid, ensuring a seamless and trouble-free experience.",
  },
];

export default function Home() {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <>
        {/* Landing Page */}
        <div className="relative w-full h-[400px] md:h-[600px] lg:h-screen">
          <video
            autoplay={true}
            controls={false}
            loop={true}
            muted={true}
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={require("../assets/bg/bg_landing.mp4")}
          />
          <div className=" flex flex-col bg-secondary-color w-full absolute top-0 h-full bg-opacity-50 px-8 lg:px-28  pt-10 md:pt-20  lg:pt-40">

            <div className="flex flex-row ">
              <div className="w-full  flex flex-col gap-4 ">
                <div>
                  <p className="text-white text-md  md:text-3xl lg:text-4xl font-semibold ">
                    Driving enhanced outcomes via collaborative synergy within a
                    dynamic digital ecosystem.
                  </p>
                </div>
                <div>
                  <p className="text-white text-xs md:text-2xl lg:text:4xl font-normal">
                    Embark on a business revolution empowered by cutting-edge
                    technology, propelling growth and innovation to unprecedented
                    heights
                  </p>
                </div>

                <div className="flex-row  gap-6  mt-5 hidden md:flex lg:flex">
                  <ScrollLink
                   smooth={true}
                    to={'services'}
                    className={`bg-primary-color  hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded`}
                  >
                    Browse Services
                  </ScrollLink>
                  <Link
                    to={PAGES.CONTACT}
                    className={`bg-secondary-color  hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded`}
                  >
                    Get In Touch
                  </Link>
                </div>
              </div>
              <div className=" hidden md:block lg:block md:w-[500px] lg:w-[800px]">
                <img src={require('../assets/images/landing.png')} />
              </div>
            </div>

            <div className="flex-row  gap-6  mt-5 flex md:hidden lg:hidden">
              <Link
                to={'#'}
                className={`bg-primary-color  hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded`}
              >
                Browse Services
              </Link>
              <Link
                to={PAGES.CONTACT}
                className={`bg-secondary-color  hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded`}
              >
                Get In Touch
              </Link>
            </div>

          </div>

        </div>


        {/* Company Overview */}
        <div className="px-8 lg:px-28">
          <div className="w-full flex flexl items-center bg-overview bg-cover bg-opacity-30 bg-no-repeat mt-20 rounded-3xl ">
            <div className="w-full flex flex-col md:flex-row lg:flex-row  rounded-3xl bg-secondary-color bg-opacity-30  px-8 py-20">
              <div className="w-full flex flex-col gap-4 mb-10 ">
                <div>
                  <p className="text-white text-4xl font-semibold">
                    GitSetCode Software Solutions
                  </p>
                </div>
                <div>
                  <p className="text-white text-lg font-normal">
                    GitSetCode Software Solutions is a reliable and principled
                    digital technology company committed to ensuring the success
                    of their clients.
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col gap-4 ">
                <div>
                  <p className="text-white text-4xl font-semibold">
                    Foremost IT Services Provider GitSetCode Software Solutions.
                  </p>
                </div>
                <div>
                  <p className="text-white text-lg font-normal">
                    GitSetCode Software Solutions stands as a premier entity in
                    the IT services sector. Our comprehensive IT services
                    encompass thorough consultation and proficient execution of
                    your projects. As an innovative and forward-looking tech
                    enterprise.
                  </p>
                </div>
                <div onClick={() => navigate(PAGES.ABOUT)}>
                  <button className="px-3 py-2 border-2 border-white text-white rounded-lg ">
                    More About GSC{" "}
                    <HiOutlineArrowRight className="inline-block ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Company Info  */}
        <div className="negative flex flex-col gap-10 items-center   bg-secondary-color bg-opacity-10 mt-14  px-8 lg:px-28 py-16 ">
          <div className="w-full flex items-center justify-center">
            <p className="text-2xl md:text-4xl lg:text-5xl font-semibold text-primary-color">
              A Tech Firm Focused on Solutions
            </p>
          </div>
          <div className="w-full hidden md:flex  items-center justify-center px-[15%] mb-8">
            <p className="text-center text-lg">
              In our technology-driven enterprise, we harness technological
              advancements to provide streamlined and effective resolutions.
              With an unwavering dedication to addressing challenges, we
              engineer influential answers that transform sectors and facilitate
              constructive advancements. Embracing technology's potential, we
              simplify intricate tasks, enhancing convenience for our clientele.
            </p>
          </div>

          <div className="w-full flex flex-col md:flex-row lg:flex-row flex-wrap gap-10  justify-center">
            {benefit &&
              benefit.map((item) => {
                return (
                  <div
                    style={{ zIndex: 0 }}
                    key={item.id}
                    className="w-[100%] md:w-[27%] bg-white px-4 py-4 rounded-lg flex flex-col gap-2 shadow-lg transition-transform transform hover:scale-105 relative"
                  >
                    <div className="w-full flex flex-col items-center justify-center">
                      <p className="text-8xl font-bold text-primary-color opacity-30 absolute top-[-30px]">
                        {item.serialNo}
                      </p>
                      <p className="text-2xl font-bold text-primary-color">
                        {item.title}
                      </p>
                    </div>
                    <div className="w-full flex items-center text-[15px] justify-center px-5">
                      <p>{item.content}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* Tech Stack */}
        <TechStack />

        {/* SERVICE STACK */}
        <div id="services">
          <ServiceStack />
        </div>
      </>
    </MainContainer>
  );
}
