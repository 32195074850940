import React from 'react'
import { ALL_SERVICES, MENU, PAGES } from '../constants';
import { PhoneInTalk, AlternateEmail, LocationOn } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function FooterLong() {
    return (

        <section className='bg-map bg-contain md:bg-cover bg-no-repeat'>
            <div className='bg-primary-color bg-opacity-95 py-10 px-8 lg:px-28 text-white '>

                <div className='w-full h-80 flex flex-col gap-5 items-center'>
                    <p className='text-center text-2xl md:text-4xl lg:text-5xl'>Let's get started</p>
                    <p className='text-center text-base md:text-lg w-[70%]'>When it comes to managing IT for your business. You need an expert. Let us show you what responsive, reliable and accountable IT Support looks like in the world.</p>

                    <Link to={PAGES.CONTACT} className='bg-secondary-color px-10 py-5 rounded-md text-sm md:text-lg  transition-transform hover:scale-105'>START WITH A FREE ASSESSMENT</Link>

                </div>

                <div className='flex flex-col gap-10 w-full  lg:flex-row lg:gap-5'>
                    <div className=' flex-grow flex flex-col items-center  gap-4'>
                        <h3 className='font-bold text-2xl md:text-3xl text-center'>Company</h3>
                        {
                            [{ name: MENU.ABOUT, page: PAGES.ABOUT },

                                /**
 * Commenting this line on 1 sept 2024 to remove the services tab from the webiste might make it active later
 */

                                //  { name: MENU.SERVICES, page: PAGES.SERVICES },

                                { name: MENU.CONTACTUS, page: PAGES.CONTACT }].map((i, index) => {
                                return (
                                    <a href={i.page} key={index} >
                                        <p className='hover:text-secondary-color'>{i.name}</p>
                                    </a>
                                )
                            })
                        }

                    </div>

                    <div className=' flex-grow items-center flex flex-col gap-4'>
                        <h3 className='font-bold text-2xl md:text-3xl text-center'>Address</h3>

                        <div className='gap-4 flex  flex-col'>
                            <a href="tel:+917985957362">
                                <div className='flex flex-row hover:text-secondary-color'>
                                    <PhoneInTalk />
                                    <div className='ml-5'>
                                        <p>Call Us</p>
                                        <p>+917985957362</p>
                                    </div>

                                </div>
                            </a>


                            <a href="mailto:gitsetcode.softwaresolutions@gmail.com">
                                <div className='flex flex-row hover:text-secondary-color'>
                                    <AlternateEmail />
                                    <div className='ml-5'>
                                        <p>Email Us</p>
                                        <p>gitsetcode.softwaresolutions@gmail.com</p>
                                    </div>

                                </div>

                            </a>


                            <div className='flex flex-row hover:text-secondary-color'>
                                <LocationOn />
                                <p className='ml-5'>Rohini, New Delhi Pin-110085</p>

                            </div>

                        </div>
                    </div>

                    <div className=' flex-grow  overflow-hidden flex-wrap'>
                        <h3 className='font-bold text-2xl md:text-3xl text-center'>Services</h3>
                        <div className='flex flex-row items-center justify-around'>
                            <ul >
                                {
                                    ALL_SERVICES.map((item, index) => {
                                        if (index < 5) {
                                            return (
                                                <a href="" key={index}>
                                                    <li className='mb-3 hover:text-secondary-color'>{item}</li>
                                                </a>
                                            )
                                        }
                                    })
                                }
                            </ul>
                            <ul >
                                {
                                    ALL_SERVICES.map((item, index) => {
                                        if (index >= 5) {
                                            return (
                                                <a href="" key={index}>
                                                    <li className='mb-3 hover:text-secondary-color'>{item}</li>
                                                </a>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
