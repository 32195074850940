import React from 'react'
import { HiOutlineHome } from 'react-icons/hi'
import { HiOutlineDevicePhoneMobile, HiOutlineChatBubbleOvalLeftEllipsis } from 'react-icons/hi2'

export default function Contact() {
    return (
        <section className='flex flex-col items-center'>
            <div className='py-12'>
                <p className='text-5xl font-extrabold text-center text-primary-color'>CONTACT US</p>
                <p className='text-lg text-center mt-4 text-primary-color'>Interested in our IT services or need advice? Then please get in touch and we'll be glad to help.</p>
            </div>

            <div className='flex flex-col lg:flex-row gap-10 mb-10'>
                <div class="group w-96  h-72  rounded-md px-10 shadow-lg  transition-transform transform hover:scale-105 flex flex-col justify-center items-center gap-5">
                    <HiOutlineDevicePhoneMobile className='text-secondary-color text-8xl' />
                    <div className='bg-secondary-color h-[3px] w-20  transition-all duration-300 opacity-0 group-hover:opacity-100 transform group-hover:scale-150' />
                    <p class="box-contacts-link"><a href="tel:+917985957362">+917985957362</a></p>
                </div>

                <div class="group w-96 h-72  rounded-md px-10 shadow-lg  transition-transform transform hover:scale-105 flex flex-col justify-center items-center gap-5">
                    <HiOutlineHome className='text-secondary-color text-8xl' />
                    <div className='bg-secondary-color h-[3px] w-20  transition-all duration-300 opacity-0 group-hover:opacity-100 transform group-hover:scale-150' />
                    <p class="box-contacts-link"><a href="#">Rohini New Delhi, 110085</a></p>
                </div>


                <div class="group w-96 h-72  rounded-md px-10 shadow-lg  transition-transform transform hover:scale-105 flex flex-col justify-center items-center gap-5">
                    <HiOutlineChatBubbleOvalLeftEllipsis className='text-secondary-color text-8xl' />
                    <div className='bg-secondary-color h-[3px] w-20  transition-all duration-300 opacity-0 group-hover:opacity-100 transform group-hover:scale-150' />
                    <p class="box-contacts-link"><a href="mailto:gitsetcode.softwaresolutions@gmail.com">gitsetcode.softwaresolutions@gmail.com</a></p>

                </div>

            </div>


            <div className="w-full lg:w-[50%] bg-white shadow-md p-6 rounded-lg mt-5 md:mt-0 lg:mt-0 mb-10">
                <p className="text-2xl mb-4 text-gray-600">Feel free to reach out to us!</p>
                <div className='flex flex-row gap-4 w-full'>
                    <div className="mb-4 flex-grow">
                        <label className="block text-gray-700 text-sm font-semibold mb-1">Full Name</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-4 flex-grow">
                        <label className="block text-gray-700 text-sm font-semibold mb-1">Organization</label>
                        <input
                            type="text"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>

                </div>

                <div className='flex flex-row gap-4 w-full'>
                    <div className="mb-4 flex-grow">
                        <label className="block text-gray-700 text-sm font-semibold mb-1">Email</label>
                        <input
                            type="email"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-4 flex-grow">
                        <label className="block text-gray-700 text-sm font-semibold mb-1">Phone Number</label>
                        <input
                            type="tel"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                </div>


                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-semibold mb-1">Message</label>
                    <textarea
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        rows="4"
                    ></textarea>
                </div>

                <button className="bg-primary-color w-full text-white px-4 py-2 rounded-lg hover:bg-opacity-80">
                    Submit
                </button>
            </div>

            <div>
                <p className='text-center'>You can also email us at <a className='text-secondary-color' href="mailto:gitsetcode.softwaresolutions@gmail.com">gitsetcode.softwaresolutions@gmail.com</a> </p>
            </div>

            <div className='h-1 bg-gray-400 mx-8 lg:mx-28 mt-10' />

        </section>
    )
}
