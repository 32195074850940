import React from "react";
import { MainContainer } from "../components";
import AboutImg from "../assets/images/about.png";
import { SiReactos } from "react-icons/si";
import { ImFlag } from "react-icons/im";
import { FaLayerGroup } from "react-icons/fa";
import QuickResponse from "../assets/AboutUs/QuickResponse.svg";
import Experienced from "../assets/AboutUs/Experienced.svg";
import NoGeekSpeak from "../assets/AboutUs/NoGeekSpeak.svg";
import BusinessSavvy from "../assets/AboutUs/BusinessSavvy.svg";
import OneStopShop from "../assets/AboutUs/OneStopShop.svg";
import Satisfaction from "../assets/AboutUs/Satisfaction.svg";
import Rajkishore from "../assets/Teams/Rajkishore.JPG";
import Shubhankar from "../assets/Teams/Shubhankar.JPG";
import Manglesh from "../assets/Teams/Manglesh.JPG";
import Abhishek from "../assets/Teams/Abhishek.jpg";
import Placeholder from "../assets/Teams/Placeholder.png";

export default function About() {
  const aboutList = [
    {
      id: 1,
      title: "OUR MISSION",
      description:
        "Building on our technologies and competencies creating value for our customers. We’ll achieve this by focusing on the intersection of our client’s emerging needs and the acceleration of business and technological change.",
      icon: <SiReactos className="text-2xl text-secondary-color" />,
    },
    {
      id: 2,
      title: "OUR VALUES",
      description:
        "Our values are the guiding principles upon which NanoSoft was founded and how we strive to conduct our business on a daily basis. Values establish our view of the world as we shape the future. They determine how we treat each other.",
      icon: <ImFlag className="text-2xl text-secondary-color" />,
    },
    {
      id: 3,
      title: "OUR APPROACH & CULTURE",
      description:
        "Our drive for exceptional service delivery is built on the belief that we are nothing if you are not satisfied with us. Our passion for helping you achieve your goals, no matter what, is what truly differentiates us from our competitors.",
      icon: <FaLayerGroup className="text-2xl text-secondary-color" />,
    },
  ];

  const Reasons = [
    {
      id: 1,
      title: "Quick response",
      description:
        "We can log in to your PC or server remotely and resolve many issues immediately without the wait for a technician to travel to your location.",
      icon: QuickResponse,
    },
    {
      id: 2,
      title: "Experienced",
      description:
        "In more than 12 years of IT outsourcing, we have gained experience in a wide spectrum of technologies, industries, and application types.",
      icon: Experienced,
    },
    {
      id: 3,
      title: "No geek speak",
      description:
        "You deserve to have your questions answered in plain English. Our technicians will clearly explain what is happening so you understand.",
      icon: NoGeekSpeak,
    },
    {
      id: 4,
      title: "Business savvy",
      description:
        "We design, evaluate and justify technology solutions from a thorough understanding of the business benefit for your company.",
      icon: BusinessSavvy,
    },
    {
      id: 2,
      title: "One Stop Shop",
      description:
        "We handle all aspects of your IT infrastructure including hardware, software management and any other related technology needs.",
      icon: OneStopShop,
    },
    {
      id: 3,
      title: "100% Satisfaction Guarantee",
      description:
        "We want you to be completely satisfied with our services. We will do whatever it takes to make you happy. No hassles, no problems.",
      icon: Satisfaction,
    },
  ];

  const Teams = [
    {
      id: 1,
      image: Placeholder,
      name: "Manglesh Tiwari",
      description: "Lead Mobile App Developer",
      mobileNo:  "+91XXXXXXXXXX",
    },
    // {
    //   id: 2,
    //   image: Placeholder,
    //   name: "Abhishek Tiwari",
    //   description: "Markrting Head",
    //   mobileNo: "+919811624711",
    // },
    {
      id: 3,
      image: Placeholder,
      name: "Shubhankar",
      description: "Lead Backend Developer ",
      mobileNo: "+91XXXXXXXXXX",
    },
    // {
    //   id: 4,
    //   image: Placeholder,
    //   name: "Rajkishore Jena",
    //   description: "Lead Frontend Developer",
    //   mobileNo: "+917676028856",
    // },
  ];

  return (
    <MainContainer>
      <section className="flex flex-col md:flex-row lg:flex-row">
        <div className="flex-grow flex flex-col justify-center px-8 lg:px-28 bg-secondary-color bg-opacity-30 py-10">
          <h2 className="text-6xl font-bold">About</h2>
          <h2 className="text-6xl text-secondary-color font-bold">
            GitSetCode
          </h2>
          <p className="text-lg my-4">
            At GitSetCode Software Solutions, we're passionate about crafting
            innovative and reliable software solutions tailored to meet your
            unique business needs.
          </p>
        </div>

        <div className="">
          <img src={`${AboutImg}`} alt="" />
        </div>
      </section>
      <React.Fragment>
        <div className="w-full flex flex-col sm:flex-row gap-2  px-10 py-5 mt-10 ">
          {/* Card */}
          {aboutList &&
            aboutList.map((item) => {
              return (
                <div
                  key={item.id}
                  className="border-[1px] rounded-lg px-5 py-3 flex flex-col gap-2 items-start justify-center"
                >
                  <div className="">{item.icon}</div>
                  <div>
                    <p className="text-lg font-semibold">{item.title}</p>
                  </div>
                  <div>
                    <p className="">{item.description}</p>
                  </div>
                </div>
              );
            })}
        </div>
        {/* WHY CHOOSE US */}
        <div className="w-full flex flex-col gap-3 items-center justify-center mt-10">
          <div>
            <p className="font-medium text-xl">WHY CHOOSE US</p>
          </div>
          {/* <div>
            <p className="text-2xl">6 REASONS TO PARTNER WITH US</p>
          </div> */}
          <div>
            <div className="h-[3px] bg-red-500 w-20" />
          </div>
          <div className="w-full flex flex-row gap-3 px-5 py-3  flex-wrap justify-between">
            {/* card */}
            {Reasons &&
              Reasons.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="w-full lg:w-[32%] md:w-[48%] px-2 py-2 flex flex-row "
                  >
                    <div className="w-[50%] ">
                      <img
                        src={item.icon}
                        alt={item.techName}
                        className="h-10"
                      />
                    </div>
                    <div>
                      <div>
                        <p className="text-lg font-semibold">{item.title}</p>
                      </div>
                      <div>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="w-full px-5 flex items-center text-center justify-center">
            <p className="font-medium">
              Stop wasting time and money. Contact Us Now
            </p>
          </div>
        </div>

        {/* OUR PEOPLE */}

        <div className="w-full flex flex-col gap-3 items-center justify-center text-center pt-5 pb-10">
          <div className="w-full flex items-center text-center justify-center px-2">
            <p className="text-xl">GREAT PRODUCT STARTS WITH A GREAT TEAM</p>
          </div>
          <div>
            <div className="h-[3px] bg-red-500 w-20" />
          </div>

          <div className="w-full flex flex-col lg:flex-row gap-5 items-center justify-center px-5 py-2  ">
            {Teams &&
              Teams.map((item) => {
                return (
                  <div className="w-64 flex flex-col items-center justify-center ">
                    <div className=" z-10 relative top-16">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="h-32 w-32 rounded-full border-4 border-transparent hover:border-secondary-color hover:transform hover:translate-y-[-10px] transition-transform"
                      />
                    </div>
                    <div className="relative w-full flex flex-col items-center  ">
                      <div className=" h-40 w-40 rounded-full bg-white absolute bottom-28" />

                      <div className="w-full h-48 flex flex-col items-center justify-end pb-5  bg-gray-200">
                        <p className="font-bold text-lg ">{item.name}</p>
                        <p className="text-secondary-color ">
                          {item.description}
                        </p>
                        <p>{item.mobileNo}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </React.Fragment>
    </MainContainer>
  );
}
