import React, { useState } from 'react';
import logo from '../assets/logo.png'
import { HiOutlineClock, HiPhone } from 'react-icons/hi'
import { Popover, SwipeableDrawer, List, ListItem, ListItemText, ListItemIcon, Divider, ListItemButton } from '@mui/material';
import { MoreVert, Menu, Home, Info, ContactPhone, Terminal } from '@mui/icons-material';
import { MENU, PAGES, THEME } from '../constants'
import { Box } from '@mui/system';
import { Twitter, Facebook, LinkedIn } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';


export default function TopNav({ fromPrivacy }) {
    const navigation = useNavigate();
    const [popOpen, setPopOpen] = useState(false);
    const [showmenupopup, setShowmenupopup] = useState(false)

    const navLinkStyle = ({ isActive }) => {
        return {
            backgroundColor: isActive && `${THEME.PRIMARY}`,
            color: isActive && '#ffffff'
            // backgroundColor: isActive ? 'rgba(255,255,255,0.05)' : '',

        }
    }

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setShowmenupopup(false)}
            onKeyDown={() => setShowmenupopup(false)}
        >
            <div className='flex flex-row justify-center items-center h-24 gap-4'>
                <div>
                    <Twitter fontSize='large' sx={{ color: THEME.PRIMARY }} />
                </div>
                <div>
                    <Facebook fontSize='large' sx={{ color: THEME.PRIMARY }} />
                </div>
                <div>
                    <LinkedIn fontSize='large' sx={{ color: THEME.PRIMARY }} />
                </div>
            </div>
            <Divider />
            <List>
                {[{ title: MENU.HOME, path: PAGES.INDEX }, { title: MENU.ABOUT, path: PAGES.ABOUT },

                /**
 * Commenting this line on 1 sept 2024 to remove the services tab from the webiste might make it active later
 */
                //  { title: MENU.SERVICES, path: PAGES.SERVICES },
                
                { title: MENU.CONTACTUS, path: PAGES.CONTACT }].map((item, index) => (
                    <NavLink key={index} to={`${item.path}`} className=' flex flex-row px-6 gap-3 py-3 hover:bg-primary-color hover:text-white text-[#696969]  rounded-md mb-3 mx-6 transition-all duration-100 ease-in' style={navLinkStyle}>

                        <div >
                            {index === 0 ? <Home /> : index === 1 ? <Info /> : index === 2 ? <Terminal /> : <ContactPhone />}
                        </div>
                        <p>{item.title}</p>

                    </NavLink>
                ))}
            </List>
            {/* <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
        </Box>
    );

    return (
        <div className='w-full h-20 flex flex-row items-center justify-between px-8 lg:px-28 shadow bg-white'>
            {/* LOGO and Menu */}
            <div className='flex justify-between items-center'>
                {
                    !fromPrivacy &&
                    <div className='md:hidden lg:hidden mr-5' onClick={() => setShowmenupopup(true)}>
                        <Menu className='text-6xl' fontSize='large' />
                    </div>
                }
                <React.Fragment key={'left'}>
                    <SwipeableDrawer
                        onOpen={() => { }}
                        anchor={'left'}
                        open={showmenupopup}
                        onClose={() => setShowmenupopup(false)}

                    >
                        {list()}

                    </SwipeableDrawer>
                </React.Fragment>
                <img src={logo} alt="Logo" className='h-10 block md:block lg:block  md:h-12 lg:h-14' />
            </div>

            {/* Phone and Time */}
            <div className='hidden   flex-row  md:flex lg:flex items-center justify-between'>
                <div className='flex flex-row items-center justify-between'>
                    <HiOutlineClock className='mx-2 text-3xl md:text-xl text-secondary-color font-bold' />
                    <label className=' text-lg md:text-lg '>09:00 <label className=' text-xl'>AM</label> — 05:00  <label className=' text-xl'>PM</label></label>
                </div>
                <div className='w-[2px] h-7 bg-priomary-color mx-10 md:mx-5 ' />

                <a href="tel:+917985957362">
                    <div className='flex flex-row items-center justify-between'>
                        <HiPhone className='mr-2 text-3xl md:text-xl text-secondary-color font-bold' />
                        <label className='text-lg'>+917985957362</label>
                    </div>
                </a>

            </div>

            {/* this is for mobile View */}
            <div className='lg:hidden  md:hidden'>
                <div onClick={() => setPopOpen(true)}>
                    <MoreVert />
                </div>
                <Popover
                    id={1}
                    open={popOpen}
                    anchorEl={''}
                    onClose={() => {
                        setPopOpen(false)
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className='py-4 px-8'>
                        <div className='flex flex-row items-center'>
                            <HiOutlineClock className='mr-4 text-2xl text-secondary-color font-bold' />
                            <label className=' text-md'>09:00 <label className=' text-lg'>AM</label> &nbsp; — &nbsp; 05:00  <label className=' text-lg'>PM</label></label>
                        </div>
                        <a href="tel:+917985957362">
                            <div className='flex flex-row items-center my-4'>
                                <HiPhone className='mr-4 text-2xl text-secondary-color font-bold' />
                                <label className='text-md'>+917985957362</label>
                            </div>
                        </a>

                    </div>

                </Popover>

            </div>
        </div>
    )
}
