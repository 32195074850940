import React from 'react'
import FAQSingleItem from './FAQSingleItem';
import { AiOutlineLine } from 'react-icons/ai'

const QUESTIONS = [
    {
        id: 1,
        question: "What kinds of services does your company provide?",
        answer: "Our company delivers a broad spectrum of IT services, encompassing software development, web and mobile app development, IT consultancy, SaaS solutions, digital marketing, and transformative digital solutions."
    },
    {
        id: 2,
        question: "Could you elaborate on the digital marketing services you offer?",
        answer: "We offer a comprehensive array of digital marketing services aimed at enhancing online presence and achieving marketing objectives. Our services cover search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, managing online reputation, fostering brand awareness, content marketing, email marketing, and optimizing conversion rates (CRO)."
    },
    {
        id: 3,
        question: "Which technologies does your company specialize in?",
        answer: "Our specialization spans a wide array of technologies, including but not limited to PHP, Laravel, React, React Native, JavaScript, Angular, Node.js, AWS, Azure, Docker, and more. We remain up-to-date with the latest technological advancements to provide cutting-edge solutions."
    },
    {
        id: 4,
        question: "How experienced is your team?",
        answer: "Our team comprises highly skilled professionals with extensive industry experience. Our developers, engineers, and consultants boast an average of over six years of experience and are well-versed in the latest technologies and frameworks."
    },
    {
        id: 5,
        question: "In what sectors have you previously operated?",
        answer: "We have a wide-ranging expertise across diverse industries, including finance, B2B applications, SaaS applications, healthcare, e-commerce, education, manufacturing, and more. We have successfully delivered IT solutions to clients in both public and private sectors."
    },
    {
        id: 6,
        question: "Is post-project support and maintenance available?",
        answer: "Certainly, we provide comprehensive support and maintenance services once projects are completed. We offer tailored support packages to cater to specific client needs, ensuring continuous monitoring, updates, and optimization of their systems."
    }
];


export default function FAQa({ fromContact = false }) {
    return (
        <div className="flex flex-col md:flex-row gap-5 px-8 lg:px-28 py-12  to-white">

            <div className={`flex flex-col  text-black w-[100%] ${!fromContact ? 'md:w-[50%]' : 'md:w-[100%'}`}>
                <div className='flex flex-row items-center text-secondary-color mb-5'>
                    <p className='text-2xl md:text-4xl lg:text-5xl font-bold'>FAQs </p>
                    <AiOutlineLine className='text-2xl md:text-4xl lg:text-5xl' />
                </div>
                <div>
                    {
                        QUESTIONS.map((item, index) => {
                            return (
                                <FAQSingleItem item={item} index={index} />
                            )
                        })
                    }

                </div>
            </div>

            {
                !fromContact &&
                <div className='flex flex-col  text-black w-[100%] md:w-[50%]'>
                    <div className='flex flex-row items-center text-secondary-color mb-5'>
                            <p className='text-2xl md:text-4xl lg:text-5xl font-bold'>Get In Touch </p>
                            <AiOutlineLine className='text-2xl md:text-4xl lg:text-5xl' />
                    </div>
                    <div className="w-full bg-white shadow-md p-6 rounded-lg mt-5 md:mt-0 lg:mt-0 ">
                        <h2 className="text-lg font-semibold mb-2 text-gray-400">Still have a question ?</h2>
                        <p className="text-2xl mb-4 text-gray-600">Feel free to reach out to us!</p>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-semibold mb-1">Name</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-semibold mb-1">Email</label>
                            <input
                                type="email"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-semibold mb-1">Phone Number</label>
                            <input
                                type="tel"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-semibold mb-1">Message</label>
                            <textarea
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                rows="4"
                            ></textarea>
                        </div>
                        <button className="bg-primary-color w-full text-white px-4 py-2 rounded-lg hover:bg-opacity-80">
                            Submit
                        </button>
                    </div>

                    </div>
            }
        </div>
    )
}
