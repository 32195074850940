import React from 'react'

export default function ComingSoon() {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
            <div className="max-w-sm p-8 rounded-lg shadow-md bg-white">
                <div className="text-center">
                    <h1 className="text-6xl font-bold  text-secondary-color">Alert!</h1>
                    <p className="text-2xl text-gray-800">Page Under Development</p>
                    <p className="mt-4 text-gray-600">
                        The page you are looking for is coming soon.
                    </p>
                </div>
                <div className="mt-8 flex justify-center">
                    <a
                        href="/"
                        className="  text-secondary-color font-semibold px-6 py-3 "
                    >
                        Go Back Home
                    </a>
                </div>
            </div>
        </div>
    )
}
