import React from "react";
import { Twitter, Facebook, LinkedIn } from "@mui/icons-material";
import { MENU, PAGES, THEME } from "../constants";
import { NavLink } from "react-router-dom";

export default function MenuBar() {

  const navLinkStyle = ({ isActive }) => {
    return {
      borderTop: isActive && `4px solid ${THEME.SECONDARY}`,
      backgroundColor: isActive ? 'rgba(255,255,255,0.05)' : '',

    }
  }

  return (
    <div className=" hidden w-full md:flex lg:flex justify-between items-center bg-primary-color h-16 px-8 lg:px-28 text-white">
      <div className="flex flex-row gap-4 h-full">
        {[{ title: MENU.HOME, path: PAGES.INDEX }, { title: MENU.ABOUT, path: PAGES.ABOUT },


/**
 * Commenting this line on 1 sept 2024 to remove the services tab from the webiste might make it active later
 */

          // { title: MENU.SERVICES, path: PAGES.SERVICES },  
          { title: MENU.CONTACTUS, path: PAGES.CONTACT }].map(
          (item, index) => {
            return (
              <NavLink to={`${item.path}`} key={index} className='border-t-4 border-t-transparent hover:border-t-4 hover:border-t-secondary-color hover:bg-white hover:bg-opacity-5 transition-all duration-150 ease-in  hover:animate-wiggle' style={navLinkStyle} >
                <div className=" px-6 h-full flex items-center justify-center cursor-pointer">
                  <p>{item.title}</p>
                </div>
              </NavLink>
            );
          }
        )}
      </div>
      <div className="flex flex-row justify-center items-center h-24 gap-4">
        <div className="text-white hover:text-secondary-color cursor-pointer">
          <Twitter fontSize="small" />
        </div>
        <div className="text-white hover:text-secondary-color cursor-pointer">
          <Facebook fontSize="small" />
        </div>
        <div className="text-white hover:text-secondary-color cursor-pointer">
          <LinkedIn fontSize="small" />
        </div>
      </div>
    </div>
  );
}
