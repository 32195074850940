import WebDevelopment from '../../assets/ServiceStack/web_dev.png';
import WebDesign from '../../assets/ServiceStack/web_design.png';
import MobileDev from '../../assets/ServiceStack/mobile_dev.png';
import ECommerce from '../../assets/ServiceStack/e_commerce.png';
import Reputation from '../../assets/ServiceStack/repitation_management.png';
import DigitalMarketing from '../../assets/ServiceStack/digital_marketing.png';
import GraphicDesigning from '../../assets/ServiceStack/graphic_designing.png';
import Brand from '../../assets/ServiceStack/brand.png';
import Social1 from '../../assets/ServiceStack/social-1.png';
import Social2 from '../../assets/ServiceStack/social-2.png';
import Social3 from '../../assets/ServiceStack/social-3.png';
import Seo from '../../assets/ServiceStack/seo.png';
import Carousel from './CarouselComp';

const serviceStack = [
    {
        id: 1,
        serviceList: [
            {
                id: 1,
                serviceName: "WEB DEVELOPMENT",
                icon: WebDevelopment,
            },
            {
                id: 2,
                serviceName: "WEB DESIGN",
                icon: WebDesign,
            },
            {
                id: 3,
                serviceName: "MOBILE APP DEVELOPMENT",
                icon: MobileDev,
            },
            {
                id: 4,
                serviceName: "ECOMMERCE SOLUTIONS",
                icon: ECommerce,
            },
            {
                id: 5,
                serviceName: "REPUTATION MANAGEMENT",
                icon: Reputation,
            },
            {
                id: 6,
                serviceName: "DIGITAL MARKETING",
                icon: DigitalMarketing,
            }
        ],
    },
    {
        id: 2,
        serviceList: [
            {
                id: 1,
                serviceName: "SEO",
                icon: Seo,
            },
            {
                id: 2,
                serviceName: "SOCIAL MEDIA OPTIMIZATION",
                icon: Social1,
            },
            {
                id: 3,
                serviceName: "SOCIAL MEDIA MARKETING",
                icon: Social3,
            },
            {
                id: 4,
                serviceName: "BRAND PROMOTION",
                icon: Brand,
            },
            {
                id: 5,
                serviceName: "GRAPHIC DESIGNING",
                icon: GraphicDesigning
            },
            {
                id: 6,
                serviceName: "ADWORDS/PPC",
                icon: Social2,
            },
        ],
    },
];

export default function ServiceTack() {
    return (
        <>
            <div className="w-full  flex flex-col gap-3 my-20 px-8 lg:px-28 ">
                <div className="w-full flex items-center justify-center">
                    <p className="text-2xl md:text-4xl lg:text-5xl font-semibold text-primary-color">
                        Ideal IT Solutions Tailored for Your Enterprise
                    </p>
                </div>
                <div className="w-full flex flex-col gap-5  overflow-hidden ">
                    {serviceStack &&
                        serviceStack.map((item) => {
                            return (
                                <div className='w-full'>
                                    <Carousel services={item} />
                                </div>
                            )
                        })}
                </div>
            </div>
        </>
    );
}
