import React, { useMemo } from 'react'
import { useNavigate, useNavigation } from 'react-router-dom';
import { PAGES } from '../constants';

export default function Footer({ fromPrivacy }) {
    const currentYear = useMemo(() => new Date().getFullYear());
    const navigation = useNavigate();

    const _handleNavigation = () => {
        if (fromPrivacy) {
            navigation(PAGES.INDEX)
        } else {
            navigation(PAGES.PRIVACY)
        }

    }

    return (
        <div className='h-20 bg-footer-color items-center flex flex-row justify-between px-8 lg:px-28'>
            <p className='text-white'>
                Copyright © {currentYear} GitSetCode
            </p>

            <p className='text-white cursor-pointer' onClick={_handleNavigation}>
                {fromPrivacy ? 'Back to Home' : 'Privacy Policy'}

            </p>

        </div>
    )
}
