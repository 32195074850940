import React, { useState } from 'react'
import { HiOutlineQuestionMarkCircle, HiOutlinePlus, HiOutlineMinus } from 'react-icons/hi';

export default function FAQSingleItem({ item, index }) {
    const [answerShown, setAnswerShown] = useState(false);

    const toggleAnswerShown = () => {
        setAnswerShown(!answerShown)
    }

    return (
        <div key={item.id} className={`mb-4`}>
            <div className={`flex items-center mb-2 shadow-md px-3 py-5 ${answerShown ? 'bg-primary-color text-white' : 'bg-white text-primary-color'}`}>
                <HiOutlineQuestionMarkCircle className="mr-2" size={24} />
                <h2 className="text-lg font-semibold flex-grow">{item.question}</h2>
                {answerShown ? <HiOutlineMinus onClick={toggleAnswerShown} className='text-lg cursor-pointer' /> : <HiOutlinePlus onClick={toggleAnswerShown} className='text-lg cursor-pointer' />}
            </div>
            {answerShown && <p className="text-gray-700">{item.answer}</p>}
        </div>
    )
}
